import axios from 'axios'

function validateStatus(status) {
  return status >= 200 && status < 300;
}
const webRequest = {
  get(baseUrl, query = {}) {
    let url = `${baseUrl}?`
    Object.keys(query).forEach((key) => {
      // eslint-disable-next-line
      let val = query[key]
      url += `${key}=${encodeURIComponent(val)}&`;
    });
    if (url.indexOf('&') !== -1) {
      url = url.substring(0, url.length - 1)
    }
    const options = {
      method: 'get',
      url,
      mode: 'cors',
      withCredentials: true,
      validateStatus,
    };
    return new Promise((resolve, reject) => {
      axios(options).then(
        (res) => {
          if (res.data.code === 0) {
            resolve(res.data)
          } else {
            reject(res.data)
          }
        },
        (error) => {
          reject(error.data)
        },
      );
    })
  },
  post(baseUrl, data = {}) {
    const params = data;
    const url = baseUrl
    const fd = new FormData();
    Object.keys(params).forEach((key) => {
      fd.append(key, params[key]);
    });
    const options = {
      method: 'post',
      url,
      mode: 'cors',
      withCredentials: true,
      validateStatus,
      data: fd,
    };
    return new Promise((resolve, reject) => {
      axios(options).then(
        (res) => {
          if (res.data.code === 0) {
            resolve(res.data)
          } else {
            reject(res.data)
          }
        },
        (error) => {
          reject(error.data)
        },
      );
    })
  },
}

// const isNative = navigator.userAgent.indexOf('youdaodict') !== -1
const { VUE_APP_SERVER_URL } = process.env
const isNative = window.ydk.isYD
const baseUrl = VUE_APP_SERVER_URL

function $http(url, method, params = {}) {
  const path = /^http/.test(url) ? url : `${baseUrl}${url}`
  if (isNative) {
    return new Promise((resolve, reject) => {
      window.ydk.request({
        path,
        params,
        method,
        needSign: false,
      }, (res) => {
        const { code } = res;
        if (code === 0) {
          resolve(res);
        } else {
          reject(res);
        }
      }, (e) => {
        console.log('error==', e)
        reject(e)
      });
    });
  }
  return webRequest[method](path, params);
}

export default $http
